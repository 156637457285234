import jwtDecode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
/**
 * Get fresh lemons by dozens
 * The LemonBatch() function is convenient for larger data downloads with the Lemon Add-In function. It returns Lemon data for several companies and fields on a given date as an array.
 * @customfunction LB lemonbatch
 * @helpurl https://sfjtechnologies.com/analyze/#/utilities/lemon-excel/help
 * @param {string[][]} tickers By convention, <company_ID> will always be the reference company’s Primary Equity Security (Bloomberg Ticker or ISIN), in the following format: "XXX YY Equity", e.g. "AAPL US Equity"
 * @param {string[][]} fields One of the fields (enclosed in quotation marks or as a referenced to another cell), as described in the Lemon Fields Nomenclature section of the User Guide <LINK>
 * @param  {string[][]} dates The date of the analysis, which needs to be an exact lemon 'time_stamp_source' date, in any Excel date format, e.g. 01/01/2022
 * @returns {string[][]} Lemon Batch Data
 */
async function lemonbatch(tickers, fields, dates) {
  try {
    let request_id = uuidv4();

    let t_key = "";
    for (let t1 = 0; t1 < tickers.length; t1++) {
      t_key = t_key + tickers[t1].join("");
    }

    let f_key = "";
    for (let f1 = 0; f1 < fields.length; f1++) {
      f_key = f_key + fields[f1].join("");
    }
    let d_key = "";
    for (let d1 = 0; d1 < dates.length; d1++) {
      d_key = d_key + dates[d1].join("");
    }

    const key = `lemonbatch: ${t_key}${f_key}${d_key}`;

    const cacheJsonData = await OfficeRuntime.storage.getItem(key);
    if (cacheJsonData != null) return JSON.parse(cacheJsonData);

    let accessToken = await getAccessToken();
    if (typeof accessToken != "string") {
      return accessToken;
    }

    let newDates = Array.from(Array(dates.length), () => new Array(dates[0].length));
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        newDates[i][j] = excelDateToJSDate(dates[i][j]);
      }
    }

    let debug = true;
    const lemonPayload = {
      afields: fields,
      dates: newDates,
      request_id: request_id,
      tickers: tickers,
      debug: debug,
    };

    const result = await lemonBatchPost(lemonPayload, accessToken, key);
    return result;
  } catch (err) {
    return [["There is a Problem in Function"]];
  }
}

/**
 * Get fresh lemons
 * The LemonTable() function is the most flexible Lemon Add-In function. It returns Lemon data for one or several companies / fields / dates in a single cell or an array.Calling more than a few hundred tickers / fields / dates using this function may make refreshing the data slower. For large calls please use LemonBatch instead.
 * @customfunction LT lemontable
 * @helpurl https://sfjtechnologies.com/analyze/#/utilities/lemon-excel/help
 * @param {string[][]} col by convention, <company_ID> will always be the reference company’s Primary Equity Security (Bloomberg Ticker or ISIN), in the following format: "XXX YY Equity", e.g. "AAPL US Equity"
 * @param {string[][]} header One of the fields (enclosed in quotation marks or as a referenced to another cell), as described in the Lemon Fields Nomenclature section of the User Guide <LINK>
 * @param  zdata the dates of the analysis, in any Excel date format, e.g. 01/01/2022
 * @returns {string[][]} Lemon Table Data
 */
async function lemonTable(col, header, zdata) {
  let response;
  let request_id = uuidv4();
  let key = `lemontable: ${col.join(",")}${header[0].join(",")}${zdata}`;

  const mac = await OfficeRuntime.storage.getItem("mac");

  const cacheJsonData = await OfficeRuntime.storage.getItem(key);
  if (cacheJsonData != null) return JSON.parse(cacheJsonData);

  let accessToken = await getAccessToken();
  if (typeof accessToken != "string") {
    return accessToken;
  }

  if (typeof col[0][0] == "string" && !isNaN(col[0][0])) {
    let mcol = [];
    for (let i = 0; i < col.length; i++) {
      for (let j = 0; j < col[0].length; j++) {
        mcol.push(excelDateToJSDate(col[i][j]));
      }
    }
    let mheader = header[0];
    let mzdata = zdata;

    let lemonPayload = {
      column_range: mcol,
      header_range: mheader,
      z_data: mzdata,
      request_id: request_id,
      debug: true,
      mac: mac,
    };
    response = await lemonTablePost(lemonPayload, accessToken, key);
  }

  if (typeof header[0][0] == "string" && !isNaN(header[0][0])) {
    let mcol = [];
    for (let i = 0; i < col.length; i++) {
      for (let j = 0; j < col[0].length; j++) {
        mcol.push(col[i][j]);
      }
    }

    let mheader = header[0].map((item) => excelDateToJSDate(item));
    let mzdata = zdata;

    let lemonPayload = {
      column_range: mcol,
      header_range: mheader,
      z_data: mzdata,
      request_id: request_id,
      debug: true,
      mac: mac,
    };
    response = await lemonTablePost(lemonPayload, accessToken, key);
  }

  if (typeof zdata != "string") {
    let mcol = [];
    col.forEach((item) => item.map((it) => mcol.push(it)));
    let mheader = [];
    header.forEach((item) => item.map((it) => mheader.push(it)));
    let mzdata = excelDateToJSDate(zdata);

    let lemonPayload = {
      column_range: mcol,
      header_range: mheader,
      z_data: mzdata,
      request_id: request_id,
      debug: true,
      mac: mac,
    };
    response = await lemonTablePost(lemonPayload, accessToken, key);
  }
  return response;
}

async function getAccessToken() {
  try {
    let accessToken = await OfficeRuntime.storage.getItem("accessToken");

    if (accessToken == null || accessToken == "undefined" || !accessToken) {
      return [["Problem getting token, login required"]];
    } else {
      if (accessToken) {
        try {
          const status = validateToken(accessToken);
          if (status == "Invalid Token") {
            accessToken = await postAuth();
            if (accessToken == null) {
              return [["Problem getting token, login required"]];
            }
          }
        } catch (err) {
          console.log(err)
          return err;
        }
      } else {
        accessToken = await postAuth();
        if (accessToken == null) {
          return [["Problem getting token, login required"]];
        }
      }
      return accessToken;
    }
  } catch (err) {
    console.log(err);
  }
}

function validateToken(accessToken) {
  const { exp } = jwtDecode(accessToken);
  const todayUTCString = new Date().toUTCString();
  const todayUTCSecond = new Date(todayUTCString).getTime();
  const expUTCSecond = exp * 1000;
  if (expUTCSecond < todayUTCSecond) {
    return "Invalid Token";
  } else {
    return "Valid Token";
  }
}

async function postAuth() {
  const config = await OfficeRuntime.storage.getItem("lemonConfig");
  const mac = await OfficeRuntime.storage.getItem("mac");
  if (!config) {
    return null;
  }

  const lemonConfig = config && JSON.parse(config);
  var details = {
    username: lemonConfig.username,
    password: lemonConfig.password,
    mac: mac,
  };

  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const url = `${lemonConfig.API_URL}/auth/token`;

  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).catch((err) => {
    return err;
  });
  const jsonData = await res.json();
  const saveToken = await OfficeRuntime.storage.setItem("accessToken", jsonData.access_token);
  return jsonData.access_token;
}

function excelDateToJSDate(excelDate) {
  try {
    let converted_date;
    if (isNaN(excelDate)) {
      let extract;
      if (excelDate.includes("/")) {
        extract = excelDate.split("/");
      }

      if (excelDate.includes("-")) {
        extract = excelDate.split("-");
      }

      if (excelDate.includes(" ")) {
        extract = excelDate.split(" ");
      }
      converted_date = `${extract[2]}-${extract[1]}-${extract[0]}`;
    } else {
      let date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
      converted_date = date.toISOString().split("T")[0];
    }
    return converted_date;
  } catch (err) {
    return [[err]];
  }
}

async function lemonBatchPost(lemonPayload, accessToken, key) {
  const lemonJson = await OfficeRuntime.storage.getItem("lemonConfig");
  if (!lemonJson) {
    return [["Unable to get your details, please login"]];
  }

  const lemonConfig = JSON.parse(lemonJson);
  const url = `${lemonConfig.API_URL}/excel/batch`;

  const lemonjsonData = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lemonPayload),
  }).catch(() => {
    return "err";
  });

  let response = await lemonjsonData.json();
  if (lemonjsonData.status == 200) {
    const xlcell = await changeResponseData(response.xlcell);
    const saveResponse = OfficeRuntime.storage.setItem(key, JSON.stringify(xlcell));
    return xlcell;
  }

  if (lemonjsonData == "err") {
    return [["API Call Failed"]];
  }

  if (lemonjsonData.status != 200) {
    let jsonerr = JSON.stringify(response);
    return [[jsonerr]];
  }
}

async function lemonTablePost(lemonPayload, accessToken, key) {
  const lemonJson = await OfficeRuntime.storage.getItem("lemonConfig");
  if (!lemonJson) {
    return [["Unable to get your details, please login"]];
  }

  const lemonConfig = JSON.parse(lemonJson);

  const url = `${lemonConfig.API_URL}/excel/table`;
  const lemonjsonData = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lemonPayload),
  }).catch(() => {
    return "err";
  });
  let response = await lemonjsonData.json();
  if (lemonjsonData.status == 200) {
    const xlcell = await changeResponseData(response.xlcell);
    const saveResponse = OfficeRuntime.storage.setItem(key, JSON.stringify(xlcell));
    return xlcell;
  }

  if (lemonjsonData == "err") {
    return [["API Call Failed"]];
  }

  if (lemonjsonData.status != 200) {
    let jsonerr = JSON.stringify(response);
    return [[jsonerr]];
  }
}

async function changeResponseData(item) {
  let newXlCellValue = item.map((parentArray) => {
    let a = parentArray.map((childArray) => {
      if (childArray == null) {
        return "";
      }
      return childArray;
    });
    return a;
  });
  return newXlCellValue;
}

/**
 * @customfunction DT todaynv
 * @returns {number} today nv
 */
function todaynv() {
  try {
    var jsDate = new Date();
    var excelDate = (jsDate - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
    return parseInt(excelDate);
  } catch (err) {
    return 0;
  }
}
CustomFunctions.associate("LB", lemonbatch);
CustomFunctions.associate("LT", lemonTable);

CustomFunctions.associate("LB", lemonbatch);
CustomFunctions.associate("LT", lemonTable);
CustomFunctions.associate("DT", todaynv);